import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Layout from './Layout'
import Candidate from './Candidate'
import Match from './Match'
import DistrictSelection from './DistrictSelection'
import Analytics from './Analytics'
import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";




class App extends Component {
  render() {
    return (
      <Router>
        <Route path="/" exact component={DistrictSelection} />
        <Route path="/district/:id" component={Layout} />
        <Route path="/candidate/:candidate_id" component={Candidate} />
        <Route path="/match" component={Match} />
        <Analytics />
    </Router>
    );
  }
}

export default App;
