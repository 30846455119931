import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { Link } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_DISTRICTS = gql`
{
  districts: vaalit_district {
    id
    name
    candidates: candidates_aggregate {
      aggregate {
        count
      }
    }
  }
}
`;


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  chip: {
    margin: theme.spacing.unit,
  },
  avatar: {
    backgroundColor: "#AE2375"
  },
  snackbar: {
    margin: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit * 5,
  },
  paper: {
    padding: theme.spacing.unit * 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});


function DistrictLink(props) {
  const { classes } = props;
  const renderLink = itemProps => <Link to={`/district/${props.data.id}`} {...itemProps} />;
  return (
    <ListItem  button component={renderLink}>
      <ListItemIcon>
        <Avatar className={classes.avatar}>{props.data.candidates.aggregate.count}</Avatar>
      </ListItemIcon>
      <ListItemText primary={props.data.name} />
    </ListItem>
  )
}

const topcenter = { vertical: "top", horizontal: "center" }

function FullWidthGrid(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Query query={GET_DISTRICTS}>
        {({ loading, error, data }) => {
          if (loading) {
            return <CircularProgress className={classes.progress} />
          }
          if (error) {
            return <SnackbarContent anchorOrigin={topcenter} className={classes.snackbar} message="Vaalikoneen lataamisessa tapahtui virhe, kokeile ladata sivu uudelleen" />
          }
          return (
            <Grid container spacing={24}>
              <Grid item xs={12}>

                <List component="nav">
                  {
                    data.districts && data.districts.map((district) => {
                      return (
                        <DistrictLink
                          key={district.id}
                          data={district}
                          classes={classes} />
                        )
                    })
                  }
                  </List>

              </Grid>
            </Grid>)
        }}
      </Query>
    </div>
  );
}

FullWidthGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullWidthGrid);
