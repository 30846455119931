import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';


class Analytics extends Component {
  componentWillUpdate ({ location, history }) {

        if (location.pathname === this.props.location.pathname) {
            // don't log identical link clicks (nav links likely)
            return;
        }

        if (history.action === 'PUSH') {
          const b = JSON.stringify({
            'page_location': window.location.href,
            'page_path': location.pathname
          })
          fetch(`/vaalit/event`, {method: "POST", headers: {"Content-Type": "application/json"}, body: b})

        }
    }

    render () {
        return null;
    }
}

export default withRouter(Analytics)
