import 'react-app-polyfill/ie9';
import * as Sentry from '@sentry/browser';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import pym from 'pym.js'

Sentry.init({
 dsn: "https://3db55055d2b34d0aa9b0172ce51bb9c8@sentry.io/1425904"
});

let pymChild = new pym.Child({ polling: 500 });

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h6: {
      fontFamily: "\"goudy-old-style\", \"serif\""
    }
  },
  palette: {
    primary: {
      light: '#e35aa4',
      main: '#ae2375',
      dark: '#7a0049',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#000',
    },
  },
});

const httpLink = createHttpLink({
   uri: 'https://liikenyt-vaalikone-19.apps.emblica.com/v1alpha1/graphql'
  //uri: '/graphql'
  //uri: '/v1alpha1/graphql'
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})


ReactDOM.render(
  <ApolloProvider client={client}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </ApolloProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
