import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { Query } from "react-apollo";
import gql from "graphql-tag";


const GET_CANDIDATE = gql`
query GetCandidate($id: Int!)
{
	candidate: vaalit_candidate_by_pk(id: $id) {
	  id
    name
    title
    edu
    district {
      name
    }
    profileUrl: profile_url
    imageUrl: image_url
    electionNumber: election_number
    answers(order_by: {question: {order: asc}}) {
      id
      question {
        order
        questionText: question_text
      }
      value
    }
	}
}
`;

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  progress: {
    margin: theme.spacing.unit * 5,
  },
  choice: {
    marginLeft: "-2px",
    marginRight: "-2px",
  },
  bigAvatar: {
    margin: 10,
    width: theme.spacing.unit * 30,
    height: theme.spacing.unit * 30,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});


class StaticChoice extends React.Component {
  state = {
    value: '3',
  };
  componentDidMount() {
    if (this.props.handleChange) this.props.handleChange(this.state.value)
  }

  handleChange = event => {
    if (this.props.handleChange) this.props.handleChange(event.target.value)
    this.setState({ value: event.target.value });
  };
  render() {
    const { classes } = this.props;
    //const handleChange = (v) => console.log(v)
    return (
      <FormControl component="fieldset">
          <RadioGroup
            aria-label="position"
            name="position"
            value={this.props.value}
            onChange={  this.handleChange}
            row
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={<span>täysin<br />eri<br />mieltä</span>}
              className={classes.choice}
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label=" "
              className={classes.choice}
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label=" "
              className={classes.choice}
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="4"
              control={<Radio color="primary" />}
              label=" "
              className={classes.choice}
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="5"
              control={<Radio color="primary" />}
              label={<span>täysin<br />samaa<br />mieltä</span>}
              className={classes.choice}
              labelPlacement="bottom"
            />
          </RadioGroup>
        </FormControl>
    )
  }
}


function Question(props) {
  const { classes } = props;

  return (<Grid item>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
            {props.data.question.questionText}
            </Typography>
            <StaticChoice classes={classes} value={`${props.data.value}`} />
          </Paper>
        </Grid>)
}

class Answers extends React.Component {
  //constructor(props) {
  state = {
      answers: this.props.answers || []
    };
  //}
  handleChange(orderId, value) {
    //const answers = Object.assign(this.state.answers, {[orderId]:parseInt(value)})
    //const answer_vec = this.state.questions.map(q => this.state.answers[q.question.key])
    //console.log(answer_vec)
    //if (this.props.handleChange) this.props.handleChange(answer_vec)
    //this.setState({answers: answers, answer_vec: answer_vec})
  }

  render() {
    const candidate = this.props.candidate;
    const classes = this.props.classes;
    const questions = this.props.answers.map(q => {
      return <Question key={q.id} classes={this.props.classes} data={q} />
    })
    const navigate = (<Button onClick={() => window.history.back()} variant="contained" size="large" >
          Takaisin
        </Button>)

    return (<Grid container alignItems="stretch" direction="column">
              <Paper className={classes.paper}>
                <Grid container direction="row" alignItems="stretch" spacing={8}>
                  <Grid alignItems="center" justify="center" container>
                      <Avatar src={candidate.imageUrl} className={classes.bigAvatar}></Avatar>
                  </Grid>
                  <Grid container direction="column" alignItems="center" justify="center">
                    <Grid sm={1} item>

                      <Typography inline={true} variant="h4" color="primary" gutterBottom>
                      {candidate.electionNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="column" alignItems="center" justify="center">
                    <Typography align="center" variant="h5" color="primary" gutterBottom>
                    {candidate.name}
                    </Typography>
                    <Typography align="center" variant="subtitle2" gutterBottom>
                    {candidate.district.name}
                    </Typography>
                    {candidate.title}<br />
                    {candidate.edu}<br />
                    <Typography align="center" variant="subtitle1" color="primary" gutterBottom>

                    </Typography>
                    <br />
                    <a href={candidate.profileUrl} target="_blank" rel="noopener noreferrer">Lue lisää ehdokkaasta</a>
                  </Grid>

                </Grid>
              </Paper>
              {questions}
              {navigate}
            </Grid>)
  }
}

function FullWidthGrid(props) {
  const { classes, match } = props;
  return (
    <div className={classes.root}>
      <Query query={GET_CANDIDATE} variables={{id: match.params.candidate_id}}>
      {({ loading, error, data }) => {
        if (loading) {
          return <CircularProgress className={classes.progress} />
        }

        return (
        <Grid container spacing={8}>
          <Answers classes={classes} candidate={data.candidate} answers={data.candidate.answers} />
        </Grid>
      )}}
      </Query>
    </div>
  );
}

FullWidthGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullWidthGrid);
