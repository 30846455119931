import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';

import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_QUESTIONS = gql`
{
  questions: vaalit_question(order_by: {order: asc, id: asc}) {
    id
    key: id
    order
    questionText: question_text
  }
}

`;

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  progress: {
    margin: theme.spacing.unit * 5,
  },
  choice: {
    marginLeft: "-2px",
    marginRight: "-2px",
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});


class Choice extends React.Component {
  state = {
    value: '3',
  };
  componentDidMount() {
    if (this.props.handleChange) this.props.handleChange(this.state.value)
  }

  handleChange = event => {
    if (this.props.handleChange) this.props.handleChange(event.target.value)
    this.setState({ value: event.target.value });
  };
  render() {
    const { classes } = this.props;
    //const handleChange = (v) => console.log(v)
    return (
      <FormControl component="fieldset">
          <RadioGroup
            aria-label="position"
            name="position"
            value={this.state.value}
            onChange={  this.handleChange}
            row
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={<span>täysin<br />eri<br />mieltä</span>}
              className={classes.choice}
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label=""
              className={classes.choice}
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label=""
              className={classes.choice}
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="4"
              control={<Radio color="primary" />}
              label=""
              className={classes.choice}
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="5"
              control={<Radio color="primary" />}
              label={<span>täysin<br />samaa<br />mieltä</span>}
              className={classes.choice}
              labelPlacement="bottom"
            />
          </RadioGroup>
        </FormControl>
    )
  }
}


function Question(props) {
  const { classes } = props;
  return (<Grid item>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
            {props.data.questionText}
            </Typography>
            <Choice classes={classes} handleChange={props.handleChange} />
          </Paper>
        </Grid>)
}

class Questions extends React.Component {
  //constructor(props) {
  state = {
      questions: this.props.questions,
      answers: {},
      answer_vec: [],
      district: parseInt(this.props.district)
    };
  //}
  handleChange(orderId, value) {
    const answers = Object.assign(this.state.answers, {[orderId]:parseInt(value)})
    const answer_vec = this.state.questions.map(q => this.state.answers[q.key])
    //console.log(answer_vec)
    //if (this.props.handleChange) this.props.handleChange(answer_vec)
    this.setState({answers: answers, answer_vec: answer_vec})
  }

  render() {
    const questions = this.state.questions.map(q => {
      return <Question key={q.key} classes={this.props.classes} data={q} handleChange={(v) => this.handleChange(q.key, v)} />
    })
    const navigate = (<Button component={props => <Link
                        to={{
                          pathname: "/match",
                          state: { answers: this.state.answer_vec, district: this.state.district }
                        }}
                        {...props}
                         />} variant="contained" size="large" color="primary">
          Löydä ehdokkaasi
        </Button>)

    return (<Grid container alignItems="stretch" direction="column">
              <Button onClick={() => window.history.back()} variant="contained" size="large" >
              Takaisin
              </Button>
              {questions}
              {navigate}
            </Grid>)
  }
}

function FullWidthGrid(props) {
  const { classes, match } = props;
  return (
    <div className={classes.root}>
      <Query query={GET_QUESTIONS}>
      {({ loading, error, data }) => {
        if (loading) {
          return <CircularProgress className={classes.progress} />
        }

        return (
        <Grid container spacing={8}>
          <Questions classes={classes} district={match.params.id} questions={data.questions} />
        </Grid>
      )}}
      </Query>
    </div>
  );
}

FullWidthGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullWidthGrid);
