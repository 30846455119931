import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_CANDIDATES = gql`
{
  questions {
    edges {
      question: node {
        key: id
        id: questionId
        order
        questionText
      }
    }
  }
}
`;

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  progress: {
    margin: theme.spacing.unit * 5,
  },
  choice: {
    marginLeft: "0px",
    marginRight: "0px",
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  bigAvatar: {
    margin: 10,
    width: theme.spacing.unit * 20,
    height: theme.spacing.unit * 20,
  },
  badge: {
    top: '-50',
    right: -30,
  },
});


class MatchResult extends React.Component {
  state = {
    loading: true,
    items: []
  };
  componentDidMount() {
    let p = fetch(`/vaalit/match`, {method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify(this.props.location.state)})
    .then(r=>r.json())
    .then(r=>r.results)

    setTimeout(() => {
      p.then(items=>this.setState({items, loading: false}))
    }, 300)
  }

  render() {
    const { classes, match, location } = this.props;
    //const score = (<Chip label={`${item.score*100}%`} color="primary" variant="outlined" />)
    if (this.state.loading) {
      return (
        <Grid alignItems="center" justify="center" container>
          <Typography  variant="subtitle2" color="primary" align="center" gutterBottom>
          Etsitään parasta ehdokasta....
          </Typography>
          <CircularProgress className={classes.progress} />
        </Grid>)
    }

    return (
      <div className={this.props.classes.root}>
      <Typography  variant="body1" color="primary" align="center" gutterBottom>
      Ehdokkaat on järjestetty valintojesi perusteella järjestykseen. Lähinnä vastauksiasi olevat ehdokkaat ovat ylempänä.
      </Typography>
        <Grid container
  direction="column"
  justify="center"
  alignItems="stretch">
        {

          this.state.items.map(item => {
            let score_description = null
            if (item.score >= 0.7) {
              score_description = "Ehdokkaan vastaukset ovat lähellä omiasi"
            }
            if (item.score >= 0.8) {
              score_description = "Ehdokkaan vastaukset ovat todella lähellä omiasi!"
            }
            if (item.score >= 0.9) {
              score_description = "Ehdokkaan vastaukset vastaavat yli 90% omiasi!"
            }
            if (item.score === 1.0) {
              score_description = "Ehdokas on vastannut täysin samoin kuin sinäkin!"
            }
            return (
              <Paper key={item.number} className={classes.paper}>
                <Grid container direction="row" alignItems="stretch" spacing={8}>
                  <Grid alignItems="center" justify="center" container>
                      <Avatar src={item.image_url} className={classes.bigAvatar}></Avatar>
                  </Grid>
                  <Grid container direction="column" alignItems="center" justify="center">
                    <Grid sm={1} item>

                      <Typography inline={true} variant="h4" color="primary" gutterBottom>
                      {item.number}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="column" alignItems="center" justify="center">
                    <Typography align="center" variant="h5" color="primary" gutterBottom>
                    {item.name}
                    </Typography>
                    {item.title}<br />
                    <Typography align="center" variant="subtitle1" color="primary" gutterBottom>
                    {score_description}
                    </Typography>
                    <br />
                    <Link to={`/candidate/${item.candidate_id}`} >Katso ehdokkaan vastaukset</Link>
                    <a href={item.profile_url} target="_blank" rel="noopener noreferrer">Lue lisää ehdokkaasta</a>
                  </Grid>

                </Grid>
              </Paper>
            )
          })

        }
        <Button onClick={() => window.history.back()} variant="contained" size="large" >
        Takaisin
        </Button>
        </Grid>
      </div>
    );
  }
}



export default withStyles(styles)(MatchResult);
